<template>
  <div class="courses-details">

    <breadcrumb
      :title="'Подробности курса'"
      :subtitle="courseDetails.title ? courseDetails.title : ''"
      :action-button="true"
      :action-button-text="'Начать обучения'"
      :action-button-link="`/course-details/${id}/fullscreen`"
    />

    <div class="mentor-details py-4">
      <b-container>
        <b-row>
          <b-col
            class="mb-4"
            sm="12"
            md="6"
            lg="4"
          >
            <div class="d-flex align-items-center">
              <div class="media  p-0">
                <b-avatar
                  size="80"
                  variant="light"
                  class="mr-2"
                  :src="mentorDetails.photo ? `https://edu.startupchoikhona.tj/backend/${mentorDetails.photo}`: ''"
                />
              </div>
              <div>
                <h6 class="text-muted">
                  Ментор:
                </h6>

                <h4>{{ mentorDetails.name }}</h4>

                <b-badge
                  class="profile-badge"
                  variant="light-primary"
                >
                  Преподователь
                </b-badge>
              </div>
            </div>
          </b-col>

          <b-col
            class="d-flex align-items-center mb-4"
            sm="12"
            md="6"
            lg="4"
          >
            <div class="">
              <h6 class="text-muted">
                Тип курса:
              </h6>
              <h4>{{ courseDetails.type ? (courseDetails.type === 1 ? 'Курс' : 'Тест') : '' }}</h4>
            </div>
          </b-col>

          <b-col
            class="d-flex align-items-center mb-4"
            sm="12"
            md="6"
            lg="4"
          >
            <div class="">
              <h6 class="text-muted mb-0">
                Рейтинг:
              </h6>
              <b-form-rating
                class="px-0"
                no-border
                value="5"
                show-value
                inline
                readonly
                variant="warning"
              />
            </div>
          </b-col>

        </b-row>

        <b-row>
          <b-col class="my-4">
            <b-tabs
              pills
            >
              <b-tab
                title="Обзор"
                active
              >
                <b-card-text v-html="about" />
              </b-tab>
              <b-tab title="Учебный план">
                <!-- types -->
                <app-collapse
                  accordion
                  type="margin"
                >

                  <app-collapse-item
                    v-for="(module, indexModule) in modules"
                    :key="indexModule"
                    :title="module.name"
                  >

                    <app-collapse
                      accordion
                      type="margin"
                    >
                      <app-collapse-item
                        v-for="(head, indexHead) in module.childs"
                        :key="indexHead"
                        :title="head.name"
                        :preview-action="head.type===2 ? true : false"
                        :preview-action-link="head.type===2 ? `/course-details/${id}/fullscreen/${indexModule}/-1/${indexHead}` : ''"
                      >
                        <app-collapse
                          accordion
                          type="margin"
                        >
                          <app-collapse-item
                            v-for="(lesson, indexLesson) in head.lessons"
                            v-if="head.type===1"
                            :key="indexLesson"
                            :title="lesson.name"
                            :preview-action="true"
                            :preview-action-link="`/course-details/${id}/fullscreen/${indexModule}/${indexHead}/${indexLesson}`"
                          />

                        </app-collapse>
                      </app-collapse-item>
                    </app-collapse>
                  </app-collapse-item>
                </app-collapse>
              </b-tab>
              <b-tab
                  title="Оценка курса"
                  v-if="userData"
              >
                <h3 v-if="questions.length === 0">Благодарим вас за оценку курса и платформы!</h3>
                <b-card
                    border-variant="gradient-primary"
                    header-bg-variant="gradient-primary"
                    header-text-variant="white" v-else>
                  <template #header>
                    <h3 class="text-white w-100 font-weight-bolder"><span class="float-left"
                    >Вопросы </span><span
                        class="float-right"
                    >Вопрос {{ currentPage }} из {{ rows }}</span></h3>
                  </template>
                  <div class="p-1">
                    <b-pagination
                        v-model="currentPage"
                        hide-goto-end-buttons
                        next-class="d-none w-0"
                        prev-class="d-none w-0"
                        :total-rows="rows"
                        :per-page="perPage"
                        class="w-100"
                        align="fill"
                        size="lg"
                        limit="20"
                        :disabled="true"
                    />
                    <b-card-text class="mt-1 text-black"><h4 class="text-black"
                                                             v-html="questions[currentPage-1] ? questions[currentPage-1].text : ''"
                    ></h4></b-card-text>
                    <b-form-group label="Выберите вариант" v-slot="{ ariaDescribedby }"
                                  v-if="questions[currentPage-1] && questions[currentPage-1].type === 'rating'"
                    >
                        <b-form-radio class="mt-1 text-black" v-model="selectedTest" :aria-describedby="ariaDescribedby"
                                      name="some-radios" :value="value"
                                      v-for="(value, index) in [1, 2, 3, 4, 5]" :key="index"
                        >
                          <span v-html="value"></span>
                        </b-form-radio>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }"
                                  v-if="questions[currentPage-1] && questions[currentPage-1].type === 'text'"
                    >
                      <b-form-textarea
                          v-model="selectedTest"
                          rows="3"
                      />
                    </b-form-group>
                    <div v-if="currentPage < rows">
                      <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="gradient-primary"
                          class="mr-1"
                          @click="nextTest"
                      >
                        Продолжить
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="gradient-primary"
                          @click="successTest"
                          pill
                      >
                        Завершить
                      </b-button>
                    </div>
                  </div>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>

    </div>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Breadcrumb from '@/views/landing/components/Breadcrumb.vue'
import {
  BContainer,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BFormRating,
  BTab,
  BTabs,
  BCard,
  BCardText,
  BPagination,
  BFormGroup,
  BFormRadio,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    Breadcrumb,
    BContainer,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BFormRating,
    BTab,
    BTabs,
    BCard,
    BCardText,
    BPagination,
    BFormGroup,
    BFormRadio,
    BButton,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      about: '',
      courseDetails: {},
      mentorDetails: {},
      modules: [],
      classAnswer: '',
      selectedTest: '',
      currentPage: 1,
      perPage: 1,
      rows: 15,
      questions: [],
      answeredQuestion: [],
      correctAnswer: 0,
      wrongAnswer: 0,
      showTestReport: false,
    }
  },
  setup() {
    const { id } = router.currentRoute.params
    return {
      id,
    }
  },
  created() {
    this.view()
    this.getFeedbackQuestions()
  },
  methods: {
    nextTest() {
      if (this.selectedTest != '') {
        this.answeredQuestion.push({
          question: this.questions[this.currentPage - 1].text,
          answer: this.selectedTest,
        })
        this.selectedTest = ''
        this.currentPage += 1
      }
    },
    skipTest() {
      this.selectedTest = ''
      this.answeredQuestion.push({
        question: this.questions[this.currentPage - 1].text,
        answer: this.selectedTest,
      })
      this.currentPage += 1
    },
    successTest() {
      this.answeredQuestion.push({
        question: this.questions[this.currentPage - 1].text,
        answer: this.selectedTest,
      })
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/website/courses/${this.id}/feedback/save`, {
        questions: this.answeredQuestion,
      }).then(response => {
        this.questions = [];
        this.currentPage = 1
        this.selectedTest = ''
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Спасибо',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            text: 'Для отзыва',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        })
      })
      console.log(this.answeredQuestion)
    },
    view() {
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/website/courses/${this.id}/view`, {})
        .then(response => {
          const { data } = response.data
          this.about = data.about
          this.courseDetails = data
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/website/courses/${this.id}/modules`, {})
        .then(response => {
          const { data } = response
          this.modules = data
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/website/courses/${this.id}/mentor`, {})
        .then(response => {
          const { data } = response.data
          this.mentorDetails = data
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
    },
    getFeedbackQuestions() {
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/website/courses/${this.id}/feedback`, {})
        .then(response => {
          const { data } = response.data
          this.rows = data.length
          this.questions = data
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
    },
  },
}
</script>

<style scoped>

</style>
